/*
 * @author YangLiQiChun
 * @date 2023/4/5
 * @time 13:42
 * @desc:
 */

import { axios } from "@/api/request";

/**
 * 用户使用时长上报
 * @param parameter
 * @returns {*}
 */
export function saveUserUsageLog(parameter) {
  return axios({
    method: "post",
    url: "vmcloud/api/userUsageLog/saveUserUsageLog",
    data: parameter,
  });
}
