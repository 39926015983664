/*
 * @author YangLiQiChun
 * @date 2023/9/14
 * @time 13:50
 * @desc: 本地存储函数处理
 */

import { useLocalStorage } from "@vueuse/core";
/**
 * 存储数据时，同时记录时间戳
 * @param key
 * 存储数据的kei
 * @param value
 * 存储的具体数据内容
 * @param expirationMinutes
 * 存储时长（天, 默认7天）
 */
export function setItemWithExpiration(key, value, expirationMinutes = 7) {
  console.log("setItemWithExpiration:", !!value);
  if (!value) {
    return;
  }
  const currentTime = new Date().getTime();
  const expirationTime = currentTime + expirationMinutes * 24 * 60 * 60 * 1000;
  const data = {
    value: value,
    type: typeof value,
    expirationTime: expirationTime,
  };
  console.log("key:", key);
  console.log("value:", JSON.stringify(value));
  console.log("data:", data);
  // 先删除数据，可能会导致无法修改已存在的
  removeFromLocalStorage(key);
  // 存储数据
  useLocalStorage(key, data);
  // localStorage.setItem(key, JSON.stringify(data));
}

/**
 * 读取数据时，检查时间戳是否过期
 * @param key
 * 读取数据内容
 * @returns {*|null}
 * 如果存储时间未超时返回数据，如果过期返回null
 */
export function getItemWithExpiration(key) {
  const data = JSON.parse(localStorage.getItem(key));
  if (data && data.expirationTime) {
    const currentTime = new Date().getTime();
    if (currentTime < data.expirationTime) {
      // 数据未过期
      return data.value;
    } else {
      // 数据已过期，删除数据
      removeFromLocalStorage(key);
      return null;
    }
  }
  return null; // 数据不存在或已过期
}

// 从本地存储中移除数据
export function removeFromLocalStorage(key) {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(`Error removing from local storage for key ${key}:`, error);
  }
}
