import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import _objectSpread from "C:/data/code/vmos-web-invite/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { mapActions, useStore } from "vuex";
import { saveUserUsageLog } from "@/api/userUsageLog";
// eslint-disable-next-line no-unused-vars
import { getCurrentInstance, onBeforeUnmount, onMounted, computed, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { wxOfficialAccountLogin } from "@/api/user";
export default {
  mounted: function mounted() {
    var _this = this;
    // 在项目 A 中监听消息
    // window.addEventListener('message', (event)=> {
    //   console.log("event:", event);
    //   // 确保消息来源是项目 B 的域
    //   if (event.origin === 'http://192.168.10.240:8000') {
    //     // 处理接收到的消息
    //     console.log("接收支付页面消息:", event.data);
    //     // window.href = event.data.options.backNavUrl
    //     this.$router.go(-(Number(event.data.options.pageBackNum) - 1))
    //     setTimeout(()=>{
    //       this.$router.push({
    //         path: '/codeShop/activationCodeDetail',
    //         query: {
    //           cardData: JSON.stringify(event.data.cardData)
    //         }
    //       })
    //     }, 100)
    //     // 关闭 B 页面
    //     event.source.close();
    //   }
    // });

    this.$nextTick(function () {
      var token = _this.$utils.util.getValueByQuery("token") || _this.$route.query.token || _this.$utils.util.getCookieValue("token") || null;
      var userId = _this.$utils.util.getValueByQuery("userId") || _this.$route.query.userId || _this.$utils.util.getCookieValue("userId") || null;
      var runtime = _this.$utils.util.getValueByQuery("runtime") || _this.$route.query.runtime || _this.$utils.util.getCookieValue("runtime") || "h5";
      var channel = _this.$utils.util.getValueByQuery("channel") || _this.$route.query.channel || _this.$utils.util.getCookieValue("channel") || null;
      var version = _this.$utils.util.getValueByQuery("version") || _this.$route.query.version || _this.$utils.util.getCookieValue("version") || null;
      if (token) {
        _this.SetToken(token);
      }
      if (channel) {
        _this.SetChannel(channel);
      }
      if (runtime) {
        _this.SetRuntime(runtime);
      }
      if (version) {
        _this.SetVersion(version);
      }
      if (userId) {
        _this.SetUserId(userId);
      }
    });
  },
  methods: _objectSpread({}, mapActions(["SetChannel", "SetVersion", "SetToken", "SetUserId", "SetRuntime"])),
  setup: function setup() {
    var _app$appContext;
    var Route = useRoute();
    var Router = useRouter();
    var app = getCurrentInstance();
    var store = useStore();
    //
    // const channel = computed(() => store.getters.channel);
    var runtime = computed(function () {
      return store.getters.runtime;
    });
    var userId = computed(function () {
      return store.getters.userId;
    });
    var hideTime = computed(function () {
      return store.getters.hideTime;
    });
    var logId = computed(function () {
      return store.getters.logId;
    });
    //
    var _app$appContext$confi = app === null || app === void 0 || (_app$appContext = app.appContext) === null || _app$appContext === void 0 || (_app$appContext = _app$appContext.config) === null || _app$appContext === void 0 ? void 0 : _app$appContext.globalProperties,
      $globalTimer = _app$appContext$confi.$globalTimer,
      $utils = _app$appContext$confi.$utils;
    onMounted(function () {
      // 如果存在隐藏时间，并且上次隐藏时间和这次显示时间相差45秒

      var wechatLoginUrl = ['/agent/home', '/codeShop/home', '/codeShop/login'];
      if (isWechatWebview() && wechatLoginUrl.some(function (url) {
        return window.location.pathname.includes(url);
      })) {
        handleWechatCode(Route.query);
      }
      nextTick(function () {
        if (userId.value && !$globalTimer.timerId) {
          store.dispatch("setLogId", $utils.util.uniquelyIdentifies(userId.value));
          userUsageLog();
          if (!$globalTimer.timerId) {
            $globalTimer === null || $globalTimer === void 0 || $globalTimer.start(function () {
              userUsageLog();
            }, 30 * 1000);
          }
        }
      });

      // 添加页面可见性变化事件监听器
      document.addEventListener("visibilitychange", handleVisibilityChange);
      window.addEventListener("beforeunload", function (event) {
        // 执行一些操作，例如发送请求或保存数据
        // 请注意，你的操作可能会受到浏览器限制
        userUsageLog(true);
      });
    });
    onBeforeUnmount(function () {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    });
    var isWechatWebview = function isWechatWebview() {
      var _window, _window$toLowerCase, _ua$match;
      var ua = (_window = window) === null || _window === void 0 || (_window = _window.navigator) === null || _window === void 0 || (_window = _window.userAgent) === null || _window === void 0 || (_window$toLowerCase = _window.toLowerCase) === null || _window$toLowerCase === void 0 ? void 0 : _window$toLowerCase.call(_window);
      if ((ua === null || ua === void 0 || (_ua$match = ua.match) === null || _ua$match === void 0 ? void 0 : _ua$match.call(ua, /micromessenger/i)) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    };
    var handleWechatCode = function handleWechatCode(options) {
      // 获取当前页面的 URL
      var currentUrl = window.location.href;
      // 创建 URL 对象
      var url = new URL(currentUrl);
      // 获取 URLSearchParams 对象
      var searchParams = url.searchParams;
      // 获取特定参数的值
      var isWeChatAuth = searchParams.get("isWeChatAuth");
      var code = searchParams.get("code");
      // 如果携带了微信授权标识表示是通过微信授权后进入的
      if (parseInt(isWeChatAuth) === 1) {
        // 获取授权数据
        handleOpenId(code);
      } else if (!isWeChatAuth) {
        // 申请微信授权
        handleGetWechatCode();
      }
    };
    var handleOpenId = function handleOpenId(code) {
      wxOfficialAccountLogin({
        jsCode: code,
        loginType: 2
      }).then(function (res) {
        var _res$data;
        switch (res === null || res === void 0 ? void 0 : res.code) {
          case 200:
            if (res !== null && res !== void 0 && (_res$data = res.data) !== null && _res$data !== void 0 && _res$data.openid) {
              var _res$data2;
              store.dispatch("setOpenId", res === null || res === void 0 || (_res$data2 = res.data) === null || _res$data2 === void 0 ? void 0 : _res$data2.openid);
            }
            break;
          default:
            throw new Error(res === null || res === void 0 ? void 0 : res.msg);
        }
      }).catch(function (err) {});
    };
    var WebsiteAuth = {
      START: "https://open.weixin.qq.com/connect/oauth2/authorize?",
      RESPONSE_TYPE: "code",
      //写死传code
      SCOPE: {
        SNSAPI_BASE: "snsapi_base",
        //不弹出授权页面，直接跳转，只能获取用户openid
        SNSAPI_USERINFO: "snsapi_userinfo" //弹出授权页面，可通过openid拿到昵称、性别、所在地。并且， 即使在未关注的情况下，只要用户授权，也能获取其信息
      },
      END: "#wechat_redirect" //无论直接打开还是做页面302重定向时候，必须带此参数作为结尾
    };
    // 微信授权
    var handleGetWechatCode = function handleGetWechatCode() {
      var start_url = WebsiteAuth.START; //url前缀
      var appid = "wx94d31dc2fccec6b8"; //公众号的唯一标识

      var redirectUri;
      redirectUri = "".concat(window.location.href.split("?")[0], "?isWeChatAuth=1");

      // 获取当前页面的 URL 对象
      var url = new URL(window.location.href);

      // 获取 URL 中的查询参数
      var params = new URLSearchParams(url.search);

      // 循环遍历查询参数
      params.forEach(function (value, key) {
        if (!redirectUri.includes(key)) {
          redirectUri = "".concat(redirectUri, "&").concat(key, "=").concat(value);
        } else {}
      });
      if (parseInt(params === null || params === void 0 ? void 0 : params.isWeChatAuth) === 1) {
        return;
      }
      var redirect_uri = encodeURIComponent(redirectUri); //授权后重定向的回调链接地址， 使用 urlEncode 对链接进行处理
      var response_type = WebsiteAuth.RESPONSE_TYPE; //返回类型，写死传code
      var scope = WebsiteAuth.SCOPE.SNSAPI_BASE; //应用授权作用域
      var state = null; //重定向后会带上的state参数，可以填写a-zA-Z0-9的参数值，最多128字节
      var url_end = WebsiteAuth.END; //无论直接打开还是做页面302重定向时候，必须带此参数
      // const forcePopup = null //强制此次授权需要用户弹窗确认；默认为false；需要注意的是，若用户命中了特殊场景下的静默授权逻辑，则此参数不生效
      var targetUrl = "".concat(start_url, "appid=").concat(appid, "&redirect_uri=").concat(redirect_uri, "&response_type=").concat(response_type, "&scope=").concat(scope, "&state=").concat(state).concat(url_end);
      // 微信的重定向会导致页面多出一层，需要先返回一层
      // window.history.back()

      window.location.href = targetUrl;
    };
    var handleVisibilityChange = function handleVisibilityChange() {
      if (document.hidden) {
        // 页面不可见，执行相应的操作
        // 每次隐藏先删除原有的隐藏记录
        // 更新隐藏时间
        store.dispatch("setHideTime", new Date().getTime());
        if ($globalTimer.timerId) {
          $globalTimer.stop();
        }
      } else {
        var time = new Date().getTime();
        // 如果存在隐藏时间，并且存在上报唯一标识，并且上次隐藏时间和这次显示时间相差45秒
        if (hideTime && time - hideTime.value > 45 * 1000) {
          // 更新唯一标识
          store.dispatch("setLogId", $utils.util.uniquelyIdentifies());
          userUsageLog();
        }
        if (!$globalTimer.timerId) {
          $globalTimer === null || $globalTimer === void 0 || $globalTimer.start(function () {
            userUsageLog();
          }, 30 * 1000);
        }
      }
    };
    var userUsageLog = function userUsageLog(exitMark) {
      var _logId$value;
      if (!userId.value) {
        return;
      }
      if (!logId.value) {
        // console.error("logId不存在");
        // store.dispatch("setLogId", $utils.util.uniquelyIdentifies());
        // setTimeout(() => {
        //   userUsageLog();
        // }, 500);
        return;
      }
      if (Route.path === "/") {
        return;
      }
      var data = {
        logId: (_logId$value = logId.value) === null || _logId$value === void 0 ? void 0 : _logId$value.id,
        //唯一标识
        // client: channel.value, //客户端
        client: runtime.value === "appWebView" ? "app" : runtime.value,
        //客户端
        version: "1",
        //客户端版本号
        terminalType: $utils.util.detectTerminalType(),
        //终端类型
        accessingPages: Route.path,
        //访问页面
        exitMark: exitMark ? Route.path : "" //退出标识
      };
      saveUserUsageLog(data).then(function (res) {}).catch(function (err) {
        // $globalTimer.stop();
        console.error("saveUserUsageLog:", err);
      });
    };
    return {
      handleVisibilityChange: handleVisibilityChange
    };
  }
};