/*
 * @author YangLiQiChun
 * @date 2023/4/6
 * @time 13:32
 * @desc:
 */

// import Vue from "vue";
// import { login, getInfo, logout } from "@/api/iam/authenticate";
import { CERTIFIED_INFO, ACCESS_TOKEN, USER_ID } from "@/store/mutation-types";
import { useLocalStorage } from "@vueuse/core";


// 默认存储生产的顶级域名
let domainInfo = "domain=.vmos.cn;";
// 如果是生产环境直接存储到顶级域名
process.env.NODE_ENV === "production"
  ? (domainInfo = "domain=.vmos.cn;")
  : (domainInfo = `domain=${window.location.hostname}; port=${window.location.port || '80'};`);

const tokenStorage = useLocalStorage(ACCESS_TOKEN)
const userIdStorage = useLocalStorage(USER_ID)

const user = {
  state: {
    token: tokenStorage.value,
    userId: userIdStorage.value,
    name: "",
    avatar: "",
    info: {},
    // 实名认证状态1:已认证，其他未认证
    realNameStatus: 0,
    certifiedInfo: {
      realNameStatus: 0,
      realName: null,
      idNumber: null,
    },
    pageQueryParam: new Map(),
    // 从大屏进入的部门类型 0不显示首页部门的评分信息
    departmentType: 0,
    permissions: [], // 权限集合
  },

  mutations: {
    SET_DEPARTMENT: (state, type) => {
      // if (type) {
      //   Vue.ls.set(DEPARTMENT_TYPE, type);
      // }
      state.departmentType = type;
    },
    SET_TOKEN: (state, token) => {
      console.log("SET_TOKEN:", token);
      console.log("domainInfo:", domainInfo);
      if(token){
        document.cookie = `token=${token}; ${domainInfo} path=/`;
      }
      state.token = token;
      tokenStorage.value = token
    },
    SET_USERID: (state, userId) => {
      console.log("SET_USERID:", userId);
      if(userId){
        document.cookie = `userId=${userId}; ${domainInfo} path=/`;
      }
      state.userId = userId;
      userIdStorage.value = userId
    },
    SET_CERTIFIED_INFO: (state, certifiedInfo) => {
      console.log("SET_CERTIFIED_INFO:", certifiedInfo);
      state.certifiedInfo = certifiedInfo;
    },
    SET_NAME: (state, { name }) => {
      state.name = name;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_INFO: (state, info) => {
      state.info = info;
    },
    SET_PAGE_QUERY_PARAM: (state, { path, queryParam }) => {
      state.pageQueryParam.set(path, queryParam);
    },
    // 设置权限信息
    SET_PERMISSIONS: (state, payload) => {
      state.permissions = payload || [];
    },
  },

  actions: {
    // setDepartment({ commit }, type) {
    //   return new Promise((resolve, reject) => {
    //     if (typeof type === "number") {
    //       commit("SET_DEPARTMENT", type);
    //     }
    //     resolve();
    //   });
    // }, // 登录
    // Login({ commit }, userInfo) {
    //   return new Promise((resolve, reject) => {
    //     login(userInfo)
    //       .then((response) => {
    //         const result = response.data;
    //         commit("SET_TOKEN", result);
    //         resolve(response);
    //       })
    //       .catch((error) => {
    //         reject(error);
    //       });
    //   });
    // },

    // 获取用户信息
    // GetInfo({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     getInfo()
    //       .then((response) => {
    //         const result = response.data;
    //         commit("SET_INFO", result);
    //         commit("SET_NAME", result.name);
    //         commit("SET_AVATAR", result.avatar);
    //         commit("SET_PERMISSIONS", result.elements);
    //         resolve(response);
    //       })
    //       .catch((error) => {
    //         reject(error);
    //       });
    //   });
    // },

    // 登出
    // Logout({ state, dispatch }) {
    //   return new Promise((resolve) => {
    //     logout(state.token).finally(() => {
    //       dispatch("ClearStorage");
    //       resolve();
    //     });
    //   });
    // },

    // 设置列表查询参数
    SetPageQueryParam: ({ commit }, { path, queryParam }) => {
      commit("SET_PAGE_QUERY_PARAM", {
        path,
        queryParam,
      });
    },
    SetToken: ({ commit }, token) => {
      commit("SET_TOKEN", token);
    },
    SetUserId: ({ commit }, userId) => {
      commit("SET_USERID", userId);
    },
    SetCertifiedInfo: ({ commit }, certifiedInfo) => {
      console.log("certifiedInfo:", certifiedInfo);
      commit("SET_CERTIFIED_INFO", certifiedInfo);
    },

    // 清空
    // ClearStorage({ commit }) {
    //   return new Promise((resolve) => {
    //     // 断开socket链接
    //     // Vue.prototype.$socket &&
    //     //   Vue.prototype.$socket.disconnect &&
    //     //   Vue.prototype.$socket.disconnect();
    //     // 清空本地信息
    //     commit("SET_TOKEN", null);
    //     commit("SET_INFO", {});
    //     commit("SET_NAME", {});
    //     commit("SET_AVATAR", "");
    //     commit("CLEAR_ROUTERS");
    //     // Vue.ls.remove(ACCESS_TOKEN);
    //     resolve();
    //   });
    // },
  },
  getters: {
    token: (state) => state.token,
    userId: (state) => state.userId,
    avatar: (state) => state.avatar,
    nickname: (state) => state.name,
    userInfo: (state) => state.info,
    pageQueryParam: (state) => state.pageQueryParam,
    // 权限值
    realNameStatus: (state) => state.realNameStatus,
    certifiedInfo: (state) =>
      state.certifiedInfo || localStorage.getItem(CERTIFIED_INFO),
    permissions: (state) => state.permissions,
    departmentType: (state) => state.departmentType,
  },
};

export default user;
