import { createRouter, createWebHistory } from "vue-router";
import { useStore } from "vuex";

const routes = [
  {
    path: "/",
    name: "invite",
    // component: HomeView,
    component: () =>
      import(/* webpackChunkName: "invite" */ "@/views/invite.vue"),
  },
  {
    path: "/authCallback",
    name: "authCallback",
    meta: {
      // 设置当前路由的标题
      title: "授权",
    },
    // component: HomeView,
    component: () =>
      import(/* webpackChunkName: "invite" */ "@/views/authCallback.vue"),
  },
  {
    path: "/agent",
    children: [
      {
        path: "",
        name: "agent",
        meta: {
          // 设置当前路由的标题
          title: "",
        },
        component: () =>
          import(/* webpackChunkName: "agent" */ "@/views/agent.vue"),
      },
      
      {
        path: "points-history",
        name: "PointsHistory",
        meta: {
          // 设置当前路由的标题
          title: "积分明细",
        },
        component: () =>
          import(/* webpackChunkName: "agent" */ "@/views/agent/pointsHistory.vue"),
      },
      {
        path: "wpzz",
        name: "wpzz",
        meta: {
          // 设置当前路由的标题
          title: "",
        },
        component: () =>
          import(/* webpackChunkName: "agent" */ "@/views/agent/wpzz.vue"),
      },
      {
        path: "xyzw",
        name: "xyzw",
        meta: {
          // 设置当前路由的标题
          title: "",
        },
        component: () =>
          import(/* webpackChunkName: "agent" */ "@/views/agent/xyzw.vue"),
      },
      {
        path: "xyzwRegister",
        name: "xyzwRegister",
        meta: {
          // 设置当前路由的标题
          title: "",
        },
        component: () =>
          import(
            /* webpackChunkName: "agent" */ "@/views/agent/xyzwRegister.vue"
          ),
      },
      {
        path: "web",
        name: "agentBrowser",
        component: () =>
          import(
            /* webpackChunkName: "agent" */ "@/views/agent/agentBrowser.vue"
          ),
      },
      {
        path: "apply",
        name: "applyAgent",
        meta: {
          // 设置当前路由的标题
          title: "申请成为代理",
        },
        component: () =>
          import(
            /* webpackChunkName: "agent" */ "@/views/agent/applyAgent.vue"
          ),
      },
      {
        path: "register",
        name: "agentRegister",
        meta: {
          // 设置当前路由的标题
          title: "注册",
        },
        component: () =>
          import(
            /* webpackChunkName: "agent" */ "@/views/agent/agentRegister.vue"
          ),
      },
      {
        path: "promotionBrochure",
        name: "agentPromotionBrochure",
        meta: {
          // 设置当前路由的标题
          title: "推广手册",
        },
        component: () =>
          import(
            /* webpackChunkName: "agent" */ "@/views/agent/agentPromotionBrochure.vue"
          ),
      },
      {
        path: "settlementRules",
        name: "agentSettlementRules",
        meta: {
          // 设置当前路由的标题
          title: "结算规则",
        },
        component: () =>
          import(
            /* webpackChunkName: "agent" */ "@/views/agent/agentSettlementRules.vue"
          ),
      },
      {
        path: "home",
        name: "agentHome",
        meta: {
          // 设置当前路由的标题
          title: "代理中心",
        },
        component: () =>
          import(/* webpackChunkName: "agent" */ "@/views/agent/agentHome.vue"),
      },
      {
        path: "phone-setting",
        name: "phoneSetting",
        meta: {
          // 设置当前路由的标题
          title: "云机设置",
        },
        component: () =>
          import(
            /* webpackChunkName: "agent" */ "@/views/agent/phoneSetting.vue"
          ),
      },
      {
        path: "popularize",
        name: "agentPopularize",
        meta: {
          // 设置当前路由的标题
          title: "推广中心",
        },
        component: () =>
          import(
            /* webpackChunkName: "agent" */ "@/views/agent/agentPopularize.vue"
          ),
      },
      {
        path: "promotion",
        name: "agentPromotion",
        meta: {
          // 设置当前路由的标题
          title: "推广须知",
        },
        component: () =>
          import(
            /* webpackChunkName: "agent" */ "@/views/agent/agentPromotion.vue"
          ),
      },
      {
        path: "withdrawHome",
        name: "WithdrawHome",
        meta: {
          // 设置当前路由的标题
          title: "提现",
        },
        component: () =>
          import(
            /* webpackChunkName: "agent" */ "@/views/agent/withdrawHome.vue"
          ),
      },
      {
        path: "menberList",
        name: "menberList",
        meta: {
          // 设置当前路由的标题
          title: "用户明细",
        },
        component: () =>
          import(
            /* webpackChunkName: "agent" */ "@/views/agent/menberList.vue"
          ),
      },
      {
        path: "orderList",
        name: "orderList",
        meta: {
          // 设置当前路由的标题
          title: "订单明细",
        },
        component: () =>
          import(/* webpackChunkName: "agent" */ "@/views/agent/orderList.vue"),
      },
      {
        path: "billingList",
        name: "billingList",
        meta: {
          // 设置当前路由的标题
          title: "结算明细",
        },
        component: () =>
          import(
            /* webpackChunkName: "agent" */ "@/views/agent/billingList.vue"
          ),
      },
      {
        path: "withdraw",
        name: "withdraw",
        meta: {
          // 设置当前路由的标题
          title: "提现",
        },
        component: () =>
          import(/* webpackChunkName: "agent" */ "@/views/agent/withdraw.vue"),
      },
      {
        path: "verified",
        name: "Verified",
        meta: {
          // 设置当前路由的标题
          title: "实名认证",
        },
        component: () =>
          import(/* webpackChunkName: "agent" */ "@/views/agent/verified.vue"),
      },
      {
        path: "withdrawList",
        name: "withdrawList",
        meta: {
          // 设置当前路由的标题
          title: "我的提现方式",
        },
        component: () =>
          import(
            /* webpackChunkName: "agent" */ "@/views/agent/withdrawList.vue"
          ),
      },
      {
        path: "addWithdraw",
        name: "addWithdraw",
        meta: {
          // 设置当前路由的标题
          title: "添加提现方式",
        },
        component: () =>
          import(
            /* webpackChunkName: "agent" */ "@/views/agent/addWithdraw.vue"
          ),
      },
    ],
  },
  {
    path: "/activitys",
    children: [
      {
        path: "",
        name: "activity",
        meta: {
          // 设置当前路由的标题
          title: "",
        },
        component: () =>
          import(
            /* webpackChunkName: "activitys" */ "@/components/HelloWorld.vue"
          ),
      },
      {
        path: "20230517",
        name: "activity20230517",
        meta: {
          // 设置当前路由的标题
          title: "限时享天津设备购买资格",
        },
        component: () =>
          import(
            /* webpackChunkName: "activitys" */ "@/views/activitys/Activity_2023-05-17.vue"
          ),
      },
    ],
  },
  {
    path: "/inviteRule",
    name: "inviteRule",
    // component: HomeView,
    component: () =>
      import(/* webpackChunkName: "inviteRule" */ "@/views/inviteRule.vue"),
  },
  {
    path: "/deliver",
    children: [
      {
        path: "zdanjian",
        name: "zdanjian",
        meta: {
          title: "VMOS云手机",
        },
        component: () =>
          import(
            /* webpackChunkName: "deliver" */ "@/views/deliver/zdanjian.vue"
          ),
      },
      {
        //一键玩投放路由
        path: "yijianwan",
        name: "yijianwan",
        meta: {
          // 设置当前路由的标题
          title: "VMOS云手机",
        },
        component: () =>
          import(
            /* webpackChunkName: "deliver" */ "@/views/deliver/yijianwan.vue"
          ),
      },
    ],
  },
  {
    path: `/:pathMatch(.*)`,
    component: () =>
      import(/* webpackChunkName: "notPath" */ "@/components/HelloWorld.vue"),
  },
  {
    path: "/codeShop",
    children: [
      {
        path: "login",
        name: "codeShopLogin",
        meta: {
          title: "",
        },
        component: () =>
          import(
            /* webpackChunkName: "codeShop" */ "@/views/codeShop/codeShop-login.vue"
          ),
      },
      {
        path: "home",
        name: "codeShopHome",
        meta: {
          title: "",
        },
        component: () =>
          import(
            /* webpackChunkName: "codeShop" */ "@/views/codeShop/codeShop-home.vue"
          ),
      },
      {
        path: "level",
        name: "codeShopLevel",
        meta: {
          title: "代理等级",
        },
        component: () =>
          import(
            /* webpackChunkName: "codeShop" */ "@/views/codeShop/codeShop-level.vue"
          ),
      },
      {
        path: "wallet",
        name: "codeShopWallet",
        meta: {
          title: "余额",
        },
        component: () =>
          import(
            /* webpackChunkName: "codeShop" */ "@/views/codeShop/codeShop-wallet.vue"
          ),
      },
      {
        path: "walletOrder",
        name: "codeShopWalletOrder",
        meta: {
          title: "充值明细",
        },
        component: () =>
          import(
            /* webpackChunkName: "codeShop" */ "@/views/codeShop/codeShop-walletOrder.vue"
          ),
      },
      {
        path: "recharge",
        name: "codeRecharge",
        meta: {
          title: "充值",
        },
        component: () =>
          import(
            /* webpackChunkName: "codeShop" */ "@/views/codeShop/codeShop-recharge.vue"
          ),
      },
      {
        path: "orderList",
        name: "codeShopOrderList",
        meta: {
          title: "购买记录",
        },
        component: () =>
          import(
            /* webpackChunkName: "codeShop" */ "@/views/codeShop/codeShop-orderList.vue"
          ),
      },
      {
        path: "orderDetail",
        name: "codeShopOrderDetail",
        meta: {
          title: "购买详情",
        },
        component: () =>
          import(
            /* webpackChunkName: "codeShop" */ "@/views/codeShop/codeShop-orderDetail.vue"
          ),
      },
      {
        path: "goods",
        name: "codeShopGoods",
        meta: {
          title: "购买激活码",
        },
        component: () =>
          import(
            /* webpackChunkName: "codeShop" */ "@/views/codeShop/codeShop-goods.vue"
          ),
      },
      {
        path: "priceLevel",
        name: "codeShopPriceLevel",
        meta: {
          title: "价格梯度",
        },
        component: () =>
          import(
            /* webpackChunkName: "codeShop" */ "@/views/codeShop/codeShop-priceLevel.vue"
          ),
      },
      {
        path: "beanGiftRule",
        name: "codeShopBeanCodeGiftRule",
        meta: {
          title: "云豆赠送梯度",
        },
        component: () =>
          import(
            /* webpackChunkName: "codeShop" */ "@/views/codeShop/codeShop-beanCodeGiftRule.vue"
          ),
      },
      {
        path: "payment",
        name: "codeShopPayment",
        meta: {
          title: "安全支付",
        },
        component: () =>
          import(
            /* webpackChunkName: "codeShop" */ "@/views/codeShop/codeShop-payment.vue"
          ),
      },
      {
        path: "activationCode",
        name: "codeShopActivationCode",
        meta: {
          title: "剩余激活码",
        },
        component: () =>
          import(
            /* webpackChunkName: "codeShop" */ "@/views/codeShop/codeShop-activationCode.vue"
          ),
      },
      {
        path: "activationCodeDetail",
        name: "codeShopActivationCodeDetail",
        meta: {
          title: "激活码管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "codeShop" */ "@/views/codeShop/codeShop-activationCodeDetail.vue"
          ),
      },
      {
        path: "appGroupList",
        name: "codeShopAppGroupList",
        meta: {
          title: "预装应用组",
        },
        component: () =>
          import(
            /* webpackChunkName: "codeShop" */ "@/views/codeShop/codeShop-appGroupList.vue"
          ),
      },
      {
        path: "appGroupDetail",
        name: "codeShopAppGroupDetail",
        meta: {
          title: "编辑预装组",
        },
        component: () =>
          import(
            /* webpackChunkName: "codeShop" */ "@/views/codeShop/codeShop-appGroupDetail.vue"
          ),
      },
      {
        path: "appGroupAddApp",
        name: "codeShopAppGroupAddApp",
        meta: {
          title: "应用/文件库",
        },
        component: () =>
          import(
            /* webpackChunkName: "codeShop" */ "@/views/codeShop/codeShop-appGroupAddApp.vue"
          ),
      },
      {
        path: "userAppList",
        name: "codeShopUserAppList",
        meta: {
          title: "应用列表",
        },
        component: () =>
          import(
            /* webpackChunkName: "codeShop" */ "@/views/codeShop/codeShop-userAppList.vue"
          ),
      },
      {
        path: "shopPay",
        name: "codeShopPay",
        meta: {
          title: "支付",
        },
        component: () =>
          import(
            /* webpackChunkName: "codeShop" */ "@/views/codeShop/codeShop-pay.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  mode: "history",
  base: process.env.NODE_ENV === "production" ? "/invite/" : "/", // 基本URL
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(
    process.env.NODE_ENV === "production" ? "/invite/" : "/"
  ),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});
router.beforeEach((to, from, next) => {
  if (window._hmt) {
    if (to.href) {
      window._hmt.push(["_trackPageview", to.href]);
    }
  }
  const routerCookie = getCookieValue("routerCookie"); // 获取 URL 参数

  /** 激活码商场相关页面必须登录 */
  const Store = useStore();
  // 如果存在路由cookie
  if (routerCookie) {
    document.cookie = `routerCookie=; ${Store.getters.domainInfo} path=/`;
    let obj = JSON.parse(routerCookie);
    console.log("routerCookie:", obj);
    console.log("query:", obj.query);
    return next({
      path: obj.url,
      query: obj.query,
    });
  }
  if (
    to.path.indexOf("/codeShop/") >= 0 &&
    to.path !== "/codeShop/login" &&
    !Store.state.user.token
  ) {
    return next("/codeShop/login");
  }
  next();
});
export function getCookieValue(key) {
  const cookieString = document.cookie;
  const cookies = cookieString.split("; ");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const [name, value] = cookie.split("=");
    if (name === key) {
      return decodeURIComponent(value);
    }
  }

  // 如果没有找到相应的cookie，则返回 null
  return null;
}
export default router;
