/*
 * @author YangLiQiChun
 * @date 2023/4/6
 * @time 13:33
 * @desc:
 */

export const ACCESS_TOKEN = "token";

export const SIDEBAR_TYPE = "sidebar_type";
export const TOGGLE_MOBILE_TYPE = "is_mobile";
export const TOGGLE_NAV_THEME = "nav_theme";
export const TOGGLE_LAYOUT = "layout";
export const TOGGLE_FIXED_HEADER = "fixed_header";
export const TOGGLE_FIXED_SIDEBAR = "fixed_sidebar";
export const TOGGLE_CONTENT_WIDTH = "content_width";
export const TOGGLE_HIDE_HEADER = "auto_hide_header";
export const TOGGLE_COLOR = "color";
export const TOGGLE_WEAK = "weak";
export const TOGGLE_MULTI_TAB = "multi_tab";
export const APP_LANGUAGE = "app_language";
export const COS_TOKEN = "cos_token";
export const DEPARTMENT_TYPE = "department_type";
export const CERTIFIED_INFO = "certified_info";
export const LOG_ID = "log-id";
export const CHANNEL = "channel";
export const RUNTIME = "runtime";
export const PC_VERSION = "pc-version";
export const OPEN_ID = "openId";
export const USER_ID = "userId";
export const HIDE_TIME = "hide-time";

export const CONTENT_WIDTH_TYPE = {
  Fluid: "Fluid",
  Fixed: "Fixed",
};

export const NAV_THEME = {
  LIGHT: "light",
  DARK: "dark",
};
