// 常量集合

export default {
  
  // ---------------- 超级管理员用户ID常量 开始 -------------------
  SUPER_USER_ID: '',
  // ---------------- 超级管理员用户ID常量 结束 -------------------
  
  // ---------------- 超级管理员角色ID常量 开始 -------------------
  SUPER_ROLE_ID: '',
  // ---------------- 超级管理员角色ID常量 结束 -------------------
  
  // ---------------- 超级租户ID常量 开始 -------------------
  SUPER_TENANT_ID: '',
  // ---------------- 超级租户ID常量 结束 -------------------
  
  // ---------------- 安卓用户来源常量 开始 -------------------
  RUNTIME_ANDROID: 'appWebView',
  // ---------------- 安卓用户来源常量 结束 -------------------
  
  // ---------------- 微信小程序用户来源常量 开始 -------------------
  RUNTIME_APPLET: 'applet',
  // ---------------- 微信小程序用户来源常量 结束 -------------------
  
  // ---------------- h5网页端用户来源常量 开始 -------------------
  RUNTIME_H5: 'h5',
  // ---------------- h5网页端用户来源常量 结束 -------------------
  
  // ---------------- 支付页面使用前端平台来源常量 开始 -------------------
  PLATFORM_TYPE: 'invite',
  // ---------------- 支付页面使用前端平台来源常量 结束 -------------------
  
}
