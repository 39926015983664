/*
 * @author YangLiQiChun
 * @date 2023/4/6
 * @time 13:48
 * @desc:
 */

// import Vue from "vue";
// import { login, getInfo, logout } from "@/api/iam/authenticate";
import {
  CHANNEL,
  HIDE_TIME,
  LOG_ID,
  PC_VERSION,
  RUNTIME,
  OPEN_ID,
} from "@/store/mutation-types";
import { useLocalStorage } from "@vueuse/core";
import { removeFromLocalStorage } from "@/utils/ls";
const app = {
  state: {
    channel: null,
    pcVersion: null,
    runtime: null,
    openId: null,
    requestCount: 0,
    logId: null,
    hideTime: null,
    // 如果是生产环境直接存储到顶级域名
    domainInfo: process.env.NODE_ENV === "production"
      ? "domain=.vmos.cn;"
      : `domain=${window.location.hostname}; port=8000;`
  },

  mutations: {
    SET_HIDE_TIME: (state, hideTime) => {
      removeFromLocalStorage(HIDE_TIME);
      state.hideTime = hideTime;
      useLocalStorage(HIDE_TIME, hideTime);
    },
    SET_LOG_ID: (state, logId) => {
      removeFromLocalStorage(LOG_ID);
      // 置空数据
      state.logId = logId;
      console.log("SET_LOG_ID:", logId);
      useLocalStorage(LOG_ID, logId);
    },
    SET_REQUEST_COUNT: (state, requestCount) => {
      state.requestCount = requestCount;
      useLocalStorage(CHANNEL, requestCount);
    },
    SET_CHANNEL: (state, channel) => {
      state.channel = channel;
      useLocalStorage(CHANNEL, channel);
    },
    SET_VERSION: (state, pcVersion) => {
      state.pcVersion = pcVersion;
      useLocalStorage(PC_VERSION, pcVersion);
    },
    SET_RUNTIME: (state, runtime) => {
      state.runtime = runtime;
      useLocalStorage(RUNTIME, runtime);
    },
    SET_OPEN_ID: (state, openId) => {
      state.openId = openId;
      useLocalStorage(OPEN_ID, openId);
    },
  },

  actions: {
    setHideTime: ({ commit }, hideTime) => {
      commit("SET_HIDE_TIME", hideTime);
    },
    setOpenId: ({ commit }, openId) => {
      commit("SET_OPEN_ID", openId);
    },
    setLogId: ({ commit }, logId) => {
      commit("SET_LOG_ID", logId);
    },
    SetRequestCount: ({ commit }, requestCount) => {
      commit("SET_REQUEST_COUNT", requestCount);
    },
    SetRuntime: ({ commit }, runtime) => {
      console.log("runtime:", runtime);
      commit("SET_RUNTIME", runtime);
    },
    SetChannel: ({ commit }, channel) => {
      commit("SET_CHANNEL", channel);
    },
    SetVersion: ({ commit }, pcVersion) => {
      commit("SET_VERSION", pcVersion);
    },
  },
  getters: {
    logId: (state) => state.logId,
    hideTime: (state) => state.hideTime,
    openId: (state) => state.openId,
    channel: (state) => state.channel,
    pcVersion: (state) => state.pcVersion,
    runtime: (state) => state.runtime,
    requestCount: (state) => state.requestCount,
    domainInfo: (state) => state.domainInfo,
  },
};

export default app;
