/*
 * @author YangLiQiChun
 * @date 2023/4/5
 * @time 13:29
 * @desc:
 */

// import config from '@/config/defaultSettings';

/**
 * 设置title
 * @param title
 */
export const setDocumentTitle = function (title) {
  document.title = title;
  const ua = navigator.userAgent;
  // eslint-disable-next-line
    const regex = /\bMicroMessenger\/([\d\.]+)/
  if (regex.test(ua) && /ip(hone|od|ad)/i.test(ua)) {
    const i = document.createElement("iframe");
    i.src = "/favicon.ico";
    i.style.display = "none";
    i.onload = function () {
      setTimeout(() => {
        i.remove();
      }, 9);
    };
    document.body.appendChild(i);
  }
};

/**
 * 富文本响应
 * @param msg
 * @returns {function(*): *}
 */
export const createRichTextResponse = function (msg) {
  return function (h) {
    return h("div", {
      class: { "pre-wrap": true },
      domProps: { innerHTML: msg },
    });
  };
};

/**
 * 用正则表达式实现html编码（转义）
 * @param sHtml
 * @returns {*}
 */
export const html2Escape = function (sHtml) {
  return sHtml.replace(
    /[<>&"]/g,
    (c) =>
      ({
        "<": "&lt;",
        ">": "&gt;",
        "&": "&amp;",
        '"': "&quot;",
      }[c])
  );
};

/**
 * 用正则表达式实现html解码（反转义）
 * @param str
 * @returns {*}
 */
export const escape2Html = function (str) {
  let arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
  return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, (all, t) => arrEntities[t]);
};

/**
 * 得到纯文本
 * @param str
 * @returns {*}
 */
export const getPlainText = function (str) {
  // 去除换行符
  str = str.replace(/(\n)/g, "");
  // 去除标签
  str = str.replace(/<\/?[^>]*>/g, "");
  // 处理空格
  str = str.replace(/&nbsp;/g, "");
  // 处理引号
  str = str.replace(/&quot;/g, '"');
  return str;
};

// export const domTitle = config.title;

export async function loadScript(url){
  return new Promise((resolve, reject) => {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.onload=()=>{
      resolve()
    };
    script.onerror=()=>{
      reject()
    }
    document.body.appendChild(script);
  })
}

export default {
  setDocumentTitle,
  createRichTextResponse,
  html2Escape,
  escape2Html,
  getPlainText,
  // domTitle
};
