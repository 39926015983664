/*
 * @author YangLiQiChun
 * @date 2023/5/10
 * @time 17:33
 * @desc:
 */

import { axios } from "@/api/request";

export function login(parameter) {
  return axios({
    method: "post",
    url: "vmcloud/api/user/login",
    data: parameter,
  });
}

export function register(parameter) {
  return axios({
    method: "post",
    url: "vmcloud/api/user/register",
    data: parameter,
  });
}
// 微信公众号支付接口调用
export function wxOfficialAccountLogin(parameter) {
  return axios({
    method: "post",
    url: "vmcloud/api/user/wxOfficialAccountLogin",
    data: parameter,
  });
}
