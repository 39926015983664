import { createApp } from "vue";
// import Antd, { message } from "ant-design-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import "ant-design-vue/dist/antd.css";
import utils from "@/utils";
import enums from "@/libraries/enums";
import consts from "@/libraries/consts";
// import "vant/lib/index.css";

import VConsole from "vconsole";
// 如果不是生产地址，加载console
if (process.env.VUE_APP_NODE_ENV !== "prod") {
  new VConsole();
}

var _hmt = _hmt || [];
window._hmt = _hmt; // 修改为window 全局变量
(function () {
  var hm = document.createElement("script");
  hm.src = `https://hm.baidu.com/hm.js?397b270e729ff58c59e6326e6d95707f`;
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();

const app = createApp(App);

// 创建全局定时器对象
const globalTimer = {
  timerId: null,
  start(callback, interval) {
    this.stop(); // 先停止已有的定时器
    this.timerId = setInterval(callback, interval);
  },
  stop() {
    clearInterval(this.timerId);
    this.timerId = null;
  },
};
app.config.globalProperties.$globalTimer = globalTimer;

app.config.globalProperties.$utils = utils;
app.config.globalProperties.$enums = enums;
app.config.globalProperties.$consts = consts;
app.use(store).use(router);
app.mount("#app");
