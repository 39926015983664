/*
 * @author YangLiQiChun
 * @date 2023/4/5
 * @time 13:28
 * @desc:
 */

import axios from "axios";
import store from "@/store";
import { showLoadingToast, showToast, closeToast } from "vant";
import router from "../router";
import "vant/es/toast/style";
// 创建 axios 实例
const service = axios.create({
  // baseURL: process.env.NODE_ENV === "production" ? process.env.VUE_APP_BASE_API : "/baseUrl", // api base_url
  baseURL: process.env.VUE_APP_BASE_API, // api base_url
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    pcVersion: "invite",
    common: {
      requestSource: "wechat-miniapp",
    },
  },
  timeout: 100000, // 请求超时时间
});

const notToastArray = ['vmcloud/api/userUsageLog/saveUserUsageLog','vmcloud/api/userApp/userAppParsingInfo', 'vmcloud/api/preinstallAppGroup/userPreAppByGroup', "vmcloud/api/userApp/selectUserAppList"]

const notLogin = ['vmcloud/api/userUsageLog/saveUserUsageLog']
/**
 * 响应体兼容处理
 * @param response
 * @returns {Promise<never>|*}
 */
const responseHandle = function (response) {
  let requestCount = store.state.app.requestCount;
  if (requestCount === 0 && !notToastArray.includes(response.config.url)) {
    store.dispatch("SetRequestCount", requestCount--);
    // 只有在最后一个请求结束时调用 closeToast
    closeToast();
  }
  let res = response?.data;
  if (
    typeof response?.data === "string" &&
    response?.data?.includes("callback(")
  ) {
    const jsonpString = response?.data; // 获取 JSONP 字符串
    const start = jsonpString.indexOf("{"); // 获取有效 JSON 字符串的起始位置
    const end = jsonpString.lastIndexOf("}"); // 获取有效 JSON 字符串的结束位置
    const jsonString = jsonpString.slice(start, end + 1); // 截取有效 JSON 字符串

    res = JSON.parse(jsonString);
  }

  // 校验Http状态码
  // const res = response.data;


  switch (res.code){
    case 200:
      return res;
    case 2017:
      // 如果不是需要跳转登录的界面跳过
      if(!notLogin.includes(response.config.url)){

        store.dispatch('SetToken',null);
        store.dispatch('SetUserId',null);
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; ${store.state.app.domainInfo} path=/`;
        document.cookie = `userId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; ${store.state.app.domainInfo} path=/`;
        // setTimeout(() => {
        //   location.reload();
        // }, 1500);
        if(router.currentRoute.value.name != 'codeShopLogin'){
          router.push({name:'codeShopLogin'})
        }
      }
      return Promise.reject(res.msg);
    case 1201:
      setTimeout(() => {
        router.push('/agent/apply?codeShop=true', );
      }, 1500);
      return Promise.reject(res.msg);
      // 上传文件错误 1090：应用不存在，1093：文件存在
    case 1090:
    case 1093:
      return Promise.reject(res);
      //应用组最多可添加10个应用
    case 1088:
      return Promise.reject(res);
    default:
      console.error('catch default code:', res.code)
      break;
  }

  // if (res.code === 200) {
  //   // 代理模式下，没有包裹响应信息，需要处理
  //   return res;
  // } else if (res.code === 2017) {
  //   localStorage.removeItem("token");
  //   localStorage.removeItem("userId");
  //   document.cookie = `token=;`;
  //   document.cookie = `userId=;`;
  //   store.dispatch('SetToken',null);
  //   store.dispatch('SetUserId',null);
  //   setTimeout(() => {
  //     location.reload();
  //   }, 1500);
  //   return Promise.reject(res.msg);
  // }else if(res.code === 1201){
  //   setTimeout(() => {
  //     router.push('/agent/apply');
  //   }, 1500);
  //   return Promise.reject(res.msg);
  //
  // }
  showToast({
    message: res.msg,
  });
  return Promise.reject(res.msg);
};

const err = (error) => {
  if (error.response) {
    // const data = error.response.data;
    // if (error.response.status === 404) {
    // } else if (error.response.status === 500) {
    // }
  }
  // 判断网络错误，关闭加载中toast
  if (axios.isAxiosError(error) && error.code == axios.AxiosError.ERR_NETWORK) {
    closeToast()
    showToast('网络异常，请重试')
    return
  }
  return Promise.reject(error);
};

// request interceptor
service.interceptors.request.use((config) => {
  let requestCount = store.state.app.requestCount;

  // 移除不需要弹出toast的接口
  if (requestCount === 0 && !notToastArray.includes(config.url)) {
    store.dispatch("SetRequestCount", requestCount++);
    // 只有在第一个请求时显示 showLoadingToast
    showLoadingToast({
      duration: 0,
      message: "加载中...",
      forbidClick: true,
    });
  }
  const { token, userId } = store.state.user;
  const { channel, pcVersion } = store.state.app;
  if (token) {
    config.headers.token = token;
  }
  if (userId) {
    config.headers.userId = userId;
  }
  if (channel) {
    config.headers.channel = channel || "h5";
  }
  if (pcVersion) {
    config.headers.pcVersion = pcVersion;
  }
  // 用于处理服务器拦截客户端更新提示
  // config.headers["sec-ch-ua-platform"] = true;
  config.headers['clientType'] = 'h5';
  config.headers["Response-Wrapper"] = true;
  return config;
}, err);

// response interceptor
service.interceptors.response.use((response) => {
  // 如果返回的是流，直接返回
  if (response.data instanceof ArrayBuffer) {
    return response;
  }

  // 响应体兼容处理
  if (process.env.VUE_APP_PROXY_ENV === "true") {
    return responseHandle({
      code: 0,
      data: response.data,
    });
  } else {
    return responseHandle(response);
  }
}, err);

// const installer = {
//     vm: {},
//     install (Vue) {
//         Vue.use(VueAxios, service);
//     }
// };

export {
  // installer as VueAxios,
  service as axios,
};
