/*
 * @author YangLiQiChun
 * @date 2023/4/5
 * @time 15:32
 * @desc:
 */

// import { isPlainObject } from "lodash";

import store from "@/store";
import { showToast, closeToast } from "vant";
import consts from "@/libraries/consts";
const openId = store.state.user.openId;
// 默认存储生产的顶级域名
let domainInfo = "domain=.vmos.cn;";
// 如果是生产环境直接存储到顶级域名
process.env.NODE_ENV === "production"
  ? (domainInfo = "domain=.vmos.cn;")
  : (domainInfo = `domain=${window.location.hostname}; port=${window.location.port || '80'};`);

export function timeFix() {
  const time = new Date();
  const hour = time.getHours();
  return hour < 9
    ? "早上好"
    : hour <= 11
    ? "上午好"
    : hour <= 13
    ? "中午好"
    : hour < 20
    ? "下午好"
    : "晚上好";
}

export function welcome() {
  const arr = [
    "休息一会儿吧",
    "准备吃什么呢?",
    "要不要打一把 DOTA",
    "我猜你可能累了",
  ];
  const index = Math.floor(Math.random() * arr.length);
  return arr[index];
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
  const event = document.createEvent("HTMLEvents");
  event.initEvent("resize", true, true);
  event.eventType = "message";
  window.dispatchEvent(event);
}

export function handleScrollHeader(callback) {
  let timer = 0;

  let beforeScrollTop = window.pageYOffset;
  callback = callback || function () {};
  window.addEventListener(
    "scroll",
    () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        let direction = "up";
        const afterScrollTop = window.pageYOffset;
        const delta = afterScrollTop - beforeScrollTop;
        if (delta === 0) {
          return false;
        }
        direction = delta > 0 ? "down" : "up";
        callback(direction);
        beforeScrollTop = afterScrollTop;
      }, 50);
    },
    false
  );
}

export function isIE() {
  const bw = window.navigator.userAgent;
  const compare = (s) => bw.indexOf(s) >= 0;
  const ie11 = (() => "ActiveXObject" in window)();
  return compare("MSIE") || ie11;
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate(id = "", timeout = 1500) {
  if (id === "") {
    return;
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id));
  }, timeout);
}
export function scorePassword(pass) {
  let score = 0;
  if (!pass) {
    return score;
  }
  // award every unique letter until 5 repetitions
  const letters = {};
  for (let i = 0; i < pass.length; i++) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1;
    score += 5.0 / letters[pass[i]];
  }

  // bonus points for mixing it up
  const variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass),
  };

  let variationCount = 0;
  for (let check in variations) {
    variationCount += variations[check] === true ? 1 : 0;
  }
  score += (variationCount - 1) * 10;

  return parseInt(score, 10);
}

/**
 * 判断参数是否为空值，如果是空值则返回true，否则返回false
 * @param val
 * @returns {boolean}
 */
export function isEmpty(val) {
  // null or undefined
  if (val == null) {
    return true;
  }

  if (typeof val === "boolean") {
    return false;
  }

  if (typeof val === "number") {
    return !val;
  }

  if (val instanceof Error) {
    return val.message === "";
  }

  switch (Object.prototype.toString.call(val)) {
    // String or Array
    case "[object String]":
    case "[object Array]":
      return !val.length;

    // Map or Set or File
    case "[object File]":
    case "[object Map]":
    case "[object Set]": {
      return !val.size;
    }
    // Plain Object
    case "[object Object]": {
      return !Object.keys(val).length;
    }
  }

  return false;
}

// 创建一个从object中选定的对象
// export const pick = (object, props, defaultEmpty) => {
//   if (isPlainObject(object)) {
//     const obj = {};
//     if (Array.isArray(props)) {
//       props.map((prop) => {
//         if (typeof prop === "string") {
//           obj[prop] = object[prop];
//         }
//       });
//     } else if (isPlainObject(props)) {
//       Object.keys(props).map((prop) => {
//         const field = props[prop];
//         if (object[field] !== undefined && object[field] !== null) {
//           obj[prop] = object[field];
//         } else if (defaultEmpty) {
//           object[prop] = "";
//         }
//       });
//     }
//     return obj;
//   } else {
//     return {};
//   }
// };

// 验证属性
export const hasProp = (instance, prop) => {
  const $options = instance.$options || {};
  const propsData = $options.propsData || {};
  return prop in propsData;
};

// uuid
export const uuid = function () {
  let s = [];
  let hexDigits = "0123456789abcdef";
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] && 0x3) || 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";
  return s.join("");
};

// guid
export const guid = function () {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    let r = Math.random() * 16 || 0;
    let v = c == "x" ? r : (r && 0x3) || 0x8;
    return v.toString(16);
  });
};

// Map转化Object
export function mapToObj(map) {
  const obj = Object.create(null);
  for (const [k, v] of map) {
    obj[k] = v;
  }
  return obj;
}

// Object转化Map
export function objToMap(obj) {
  const map = new Map();
  for (const k of Object.keys(obj)) {
    map.set(k, obj[k]);
  }
  return map;
}

// 获取FormModel的字段列表
export function getFormModelFields(insForm) {
  if (insForm && insForm.fields) {
    return insForm.fields.map(({ prop }) => prop);
  } else {
    return [];
  }
}

// 补零
export function prefixZero(num, n) {
  return (Array(n).join(0) + num).slice(-n);
}

// 中文数字
export function chineseNumber(num) {
  if (!/^\d*(\.\d*)?$/.test(num)) {
    return num;
  }
  let AA = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
  let BB = ["", "十", "百", "千", "万", "亿", "点", ""];
  let a = `${num}`.replace(/(^0*)/g, "").split(".");
  let k = 0;
  let re = "";
  for (let i = a[0].length - 1; i >= 0; i--) {
    switch (k) {
      case 0:
        re = BB[7] + re;
        break;
      case 4:
        if (!new RegExp(`0{4}\\d{${a[0].length - i - 1}}$`).test(a[0])) {
          re = BB[4] + re;
        }
        break;
      case 8:
        re = BB[5] + re;
        BB[7] = BB[5];
        k = 0;
        break;
    }
    if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0) {
      re = AA[0] + re;
    }
    if (a[0].charAt(i) != 0) {
      re = AA[a[0].charAt(i)] + BB[k % 4] + re;
    }
    k++;
  }
  // 加上小数部分(如果有小数部分)
  if (a.length > 1) {
    re += BB[6];
    for (let i = 0; i < a[1].length; i++) {
      re += AA[a[1].charAt(i)];
    }
  }
  return re;
}

/**
 * 深度优先遍历的递归写法
 * @param nodes
 * @param cb
 * @param childKey
 * @returns {*}
 */
export function deepTraversal(nodes, cb, childKey = "children") {
  if (Array.isArray(nodes)) {
    for (let i = 0; i < nodes.length; i++) {
      let node = nodes[i];
      typeof cb == "function" && cb(node);
      deepTraversal(node[childKey], cb, childKey);
    }
  }
  return nodes;
}

/**
 * 深拷贝对象
 * @param obj
 * @returns {*[]}
 */
export function deepCopy(obj) {
  let result = Array.isArray(obj) ? [] : {};
  for (let key in obj) {
    // 可以循环对象和数组
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      // 可以判断对象（key）和数组（index）
      if (typeof obj[key] === "object" && obj[key] !== null) {
        // typeof 对象 | 数组 | null ，返回 'object'
        result[key] = deepCopy(obj[key]); // 递归复制
      } else {
        result[key] = obj[key];
      }
    }
  }
  return result;
}

/**
 * 通过字典的value得到name
 * @param opts 字典获取的下拉集合
 * @param value 字典值
 */
export function getOptsValueByName(opts, value) {
  let values = opts.map((item) => item.value);
  let names = opts.map((item) => item.name);
  try {
    return names[values.indexOf(`${value}`)];
  } catch (e) {
    console.error(
      "getOptsValueByName异常：",
      e,
      `\nvalue:${value}`,
      `\nvalues:${values}`,
      `\nnames:${names}`
    );
    return "";
  }
}

/**
 * 通过字典的name得到value
 * @param opts 字典获取的下拉集合
 * @param name 字典名称
 */
export function getOptsNameByValue(opts, name) {
  let values = opts.map((item) => item.value);
  let names = opts.map((item) => item.name);
  try {
    return values[names.indexOf(`${name}`)];
  } catch (e) {
    console.error(
      "getOptsNameByValue异常：",
      e,
      `\nname:${name}`,
      `\nvalues:${values}`,
      `\nnames:${names}`
    );
    return "";
  }
}

export function getCookieValue(key) {
  const cookieString = document.cookie;
  const cookies = cookieString.split("; ");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const [name, value] = cookie.split("=");
    if (name === key) {
      return decodeURIComponent(value);
    }
  }

  // 如果没有找到相应的cookie，则返回 null
  return null;
}

/**
 * 跳转原生app续费界面函数
 * @param goodId
 * 云机规格， 例如：畅享/尊享/旗舰
 * @param renewConfigId
 * 云机配置： 安卓10/H 安卓8， 与configId有区别
 */
export function handleRenewDevice(goodId = null, renewConfigId = null) {
  /** 跳转到云机续费页，批量
   * @param goodId: 云机规格， 例如：畅享/尊享/旗舰
   * @param renewConfigId: 云机配置： 安卓10/H 安卓8， 与configId有区别 */
  window.JSInterface.renewDevice(goodId, renewConfigId);
}

/**
 * 跳转原生app创建界面
 */
export function handleRedirectCreate() {
  window.JSInterface.redirectCreate();
}

/**
 * 跳转原生app购买置顶商品界面
 * @param goodId
 * 云机规格， 例如：畅享/尊享/旗舰
 * @param configId
 * 云机配置： 安卓10/H 安卓8
 * @param goodTimeId
 * 具体商品： 按量计费/30天卡
 */
export function handleCreateCVMDialog(
  goodId = null,
  configId = null,
  goodTimeId = ""
) {
  try {
    /** 跳转到创建云机页，打开弹窗，并指定商品的品类
     * @param goodId: 云机规格， 例如：畅享/尊享/旗舰
     * @param configId: 云机配置： 安卓10/H 安卓8
     * @param goodTimeId: 具体商品： 按量计费/30天卡 */
    window.JSInterface.showCreateCVMDialog(goodId, configId, goodTimeId);
  } catch (e) {
    // 如果接口调用出现异常跳转商品界面
    handleRedirectCreate();
  }
}

/**
 * 跳转原生APP我的云豆页
 */
export function handleNaviMyBeans() {
  window.JSInterface.naviMyBeans();
}
/**
 * 跳转原生APP 邀请好友页
 */
export function handleNaviInviteFriends() {
  window.JSInterface.naviInviteFriends();
}
/**
 * 跳转原生APP 云空间页
 */
export function handleNaviCloudSpace() {
  window.JSInterface.naviCloudSpace();
}
/**
 * 跳转原生APP 云空间购买
 */
export function handleNaviCloudSpaceBuy() {
  window.JSInterface.naviCloudSpaceBuy();
}

/**
 * @date 2023/9/14 11:45
 * @author YangLiQiChun
 * @description 使用时长上报相关函数
 * addBegin
 */
/**
 * 返回10位数长度userId字段，不足前面补零
 * @returns number
 */
export function formatToTenDigits(userId) {
  if (store?.state?.user?.userId || userId) {
    const numberString = userId || store.state.user.userId.toString();
    if (numberString.length >= 10) {
      // 如果数字已经有10位或更多位，直接返回
      return numberString;
    } else {
      // 不足10位时，在数字前面补零
      const leadingZeros = "0".repeat(10 - numberString.length);
      return leadingZeros + numberString;
    }
  }
}
/**
 * 随机生成一个7位的随机数
 * @returns {number}
 */
export function generateRandomSevenDigitNumber() {
  const min = 1000000; // 最小的 7 位数
  const max = 9999999; // 最大的 7 位数
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
/**
 * 得到时长上报唯一ID，返回对象
 * @returns {{time: number, id: string}}
 */
export function uniquelyIdentifies(userId) {
  if(store?.state?.user?.userId){
    let getTime = new Date().getTime();
    return {
      time: getTime,
      id: `${getTime}_${formatToTenDigits(
        userId
      )}_${generateRandomSevenDigitNumber()}`,
    };
  }else{
    return null
  }

}

/**
 * 得到使用终端
 * @returns {string}
 */
export function detectTerminalType() {
  const userAgent = window.navigator.userAgent.toLowerCase();

  switch (true) {
    case userAgent.includes("android"):
      return "android";
    case userAgent.includes("iphone"):
      return "IOS";
    case userAgent.includes("ipad"):
      return "ipad";
    case userAgent.includes("windows"):
      return "Win";
    case userAgent.includes("macintosh"):
      return "mac";
    default:
      return "other";
  }
}
/**
 * @date 2023/9/14 11:45
 * @author YangLiQiChun
 * addEnd
 */


/**
 * @date 2023/9/27 2:32
 * @author YangLiQiChun
 * @description 获取通过url传递的参数
 * addBegin
 */
export function getValueByQuery(key) {
  const url = window.location.href;
  const regex = new RegExp(`[?&]${key}=([^&#]*)`);
  const match = regex.exec(url);
  return match ? decodeURIComponent(match[1].replace(/\+/g, " ")) : null;
}
/**
 * @date 2023/9/27 2:32
 * @author YangLiQiChun
 * addEnd
 */

/**
 * @param url 跳转安卓指定界面地址
 * "cn.vmos.cloudphone.mine.ActivationCodeActivity" 激活码界面
 * "cn.vmos.cloudphone.cloudspace.CloudSpaceActivity" 云空间界面
 * @param data 字符串对象
 */
export function handleNaviAnywhere(url, data = {}) {

console.log("store.state.app:", store.state.app)
  if (store.state.app.runtime === consts.RUNTIME_ANDROID) {
    try {
      closeToast()
      window.JSInterface.naviAnywhere(url, JSON.stringify(data));
    } catch (e) {
      showToast("请使用安卓APP打开页面");
      console.error("请使用安卓APP打开页面：", e);
    }
  } else {
    handleNavigate(url, data)
  }

}
export function getAppVersionCode() {
  if (store.state.app.runtime === consts.RUNTIME_ANDROID) {
    try {
       return window.JSInterface.getAppVersionCode();
    } catch (e) {
      showToast("请使用安卓APP打开页面");
      console.error("请使用安卓APP打开页面：", e);
      return 0
    }
  }


}

/**
 * 跳转页面所需要的参数
 * @param navigateName
 * @param params
 */
export function handleNavigate(navigateName, params) {
  let url, urlParams="";
  switch (navigateName) {
    case "cn.vmos.cloudphone.cloudspace.CloudSpaceActivity":
      url = "/pages/Space/index";
      break;
  }

  switch (store.state.app.runtime) {
    case consts.RUNTIME_APPLET:
      try {

        if(typeof params === "object"){
          for (const paramsKey in params) {
            urlParams += `${paramsKey}=${params[paramsKey]}&`;
          }
          // 去除末尾的"&"符号
          urlParams = urlParams.slice(0, -1);
        }

        if (openId) {
          urlParams = `${urlParams}&openId=${openId}`;
        }

        closeToast()
        window.wx.miniProgram.redirectTo({
          url: `${url}?${urlParams}`,
          complete: (e) => {
            console.log("跳转返回", e);
          },
        });
      } catch (e) {
        showToast("请使用微信小程序打开");
        console.error("请使用微信小程序打开");
      }

      break;
    case consts.RUNTIME_H5:
      try {
        // let domainInfo = "domain=.vmos.cn;";
        // let domainInfo = "domain=192.168.10.240; port=8000;";
        if(store.state.user.token){
          document.cookie = `token=${store.state.user.token}; ${domainInfo} path=/`;
        }
        if(store.state.user.userId){
          document.cookie = `userId=${store.state.user.userId}; ${domainInfo} path=/`;
        }
        if(store.state.app.channel){
          document.cookie = `channel=${store.state.app.channel}; ${domainInfo} path=/`;
        }
        if(store.state.app.runtime){
          document.cookie = `runtime=${store.state.app.runtime}; ${domainInfo} path=/`;
        }
        if(typeof params === "object"){
          for (const paramsKey in params) {
            if(params[paramsKey]){
              urlParams = urlParams + `${paramsKey}=${params[paramsKey]}&`;
            }
          }
          // 去除末尾的"&"符号
          // urlParams = urlParams.slice(0, -1);
        }

        let userInfoObj = {
          token: store.state.user.token,
          userId: store.state.user.userId,
          channel: store.state.app.channel,
          runtime: store.state.user.runtime,
        }

        for (const paramsKey in userInfoObj) {
          if(userInfoObj[paramsKey]){
            urlParams = urlParams + `${paramsKey}=${userInfoObj[paramsKey]}&`;
          }
        }
        // 去除末尾的"&"符号
        urlParams = urlParams.slice(0, -1);


        if (openId) {
          urlParams = `${urlParams}&openId=${openId}`;
        }


        console.log("urlParams:", urlParams)

        setTimeout(()=>{
          closeToast()
          window.location.href = `${process.env.VUE_APP_H5_URL}${url}?${urlParams}`;
        }, 500)
      } catch (e) {
        showToast("请使用浏览器打开");
        console.error("请使用浏览器打开");
      }

      break;
    default:
      console.log("store.state.runtime：", store.state.app.runtime);
      break;
  }
}

/**
 * @date 2023/11/16 10:07
 * @author YangLiQiChun
 * @description 传入直接返回空间大小
 * addBegin
 */
export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(Math.abs(bytes)) / Math.log(k));
  const result = parseFloat((Math.abs(bytes) / Math.pow(k, i)).toFixed(dm));
  return `${bytes < 0 ? '-' : ''}${result} ${sizes[i]}`;
}
/**
 * @date 2023/11/16 10:07
 * @author YangLiQiChun
 * addEnd
 */

/**
 * 将 dataurl转为文件对象方便上传
 * @param {*} dataurl 
 * @param {*} filename 
 * @returns 
 */
export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  if(mime.indexOf('image/png')!=-1){
    filename += ".png"
  }else{
    filename += ".jpg"
  }
  return new File([u8arr], filename, {type:mime});
}

export default {
  getAppVersionCode,
  handleRenewDevice,
  handleRedirectCreate,
  handleCreateCVMDialog,
  handleNaviMyBeans,
  handleNaviInviteFriends,
  handleNaviCloudSpace,
  handleNaviCloudSpaceBuy,
  getCookieValue,

  timeFix,
  welcome,
  triggerWindowResizeEvent,
  handleScrollHeader,
  isIE,
  removeLoadingAnimate,
  scorePassword,
  isEmpty,
  // pick,
  hasProp,
  uuid,
  guid,
  mapToObj,
  objToMap,
  getFormModelFields,
  prefixZero,
  chineseNumber,
  deepTraversal,
  deepCopy,
  detectTerminalType,
  uniquelyIdentifies,
  formatBytes
};
