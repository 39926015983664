/*eslint max-lines: "off"*/
/*eslint camelcase: "off"*/
// 常量集合

  /** ---------------- 是否枚举 开始 -------------------**/
  export const yesOrNo= {

    // 是否-集合
    list: [
        { label: '是', name: '是', value: '1', permission: 'yes' },
    { label: '否', name: '否', value: '0', permission: 'no' },
    ],

    // 枚举键名与枚举值的映射
    
    /* 是否-是 */
    yes: '1',
    
    /* 是否-否 */
    no: '0',
    
     // 根据枚举值获取名称
    getNameByValue: function(val) {
      switch (val) {
        case '1':
          return '是'
        case '0':
          return '否'
        default:
          return val
      }
    },
    // 根据枚举名获取枚举值
    getValueByName: function(name) {
       switch (name) {
         case '是':
           return '1'
         case '否':
           return '0'
         default:
           return name
       }
    },
    // 根据枚举键名获取枚举值
    getValueByKey: function(key) {
       switch (key) {
         case 'yes':
           return '1'
         case 'no':
           return '0'
         default:
           return key
       }
    }
  }
  /*---------------- 是否枚举 结束 -------------------*/
  
  /** ---------------- 登录方式枚举 开始 -------------------**/
  export const loginType= {

    // 登录方式-集合
    list: [
        { label: '验证码登录', name: '验证码登录', value: '0', permission: 'codeLogin' },
    { label: '密码登录', name: '密码登录', value: '1', permission: 'passLogin' },
    ],

    // 枚举键名与枚举值的映射
    
    /* 登录方式-验证码登录 */
    codeLogin: '0',
    
    /* 登录方式-密码登录 */
    passLogin: '1',
    
     // 根据枚举值获取名称
    getNameByValue: function(val) {
      switch (val) {
        case '0':
          return '验证码登录'
        case '1':
          return '密码登录'
        default:
          return val
      }
    },
    // 根据枚举名获取枚举值
    getValueByName: function(name) {
       switch (name) {
         case '验证码登录':
           return '0'
         case '密码登录':
           return '1'
         default:
           return name
       }
    },
    // 根据枚举键名获取枚举值
    getValueByKey: function(key) {
       switch (key) {
         case 'codeLogin':
           return '0'
         case 'passLogin':
           return '1'
         default:
           return key
       }
    }
  }
  /*---------------- 登录方式枚举 结束 -------------------*/
  
  /** ---------------- 代理等级枚举 开始 -------------------**/
  export const agentType= {

    // 代理等级-集合
    list: [
        { label: '黑铁', name: '黑铁', value: 'A', permission: 'blackIron' },
    { label: '青铜', name: '青铜', value: 'B', permission: 'bronze' },
    { label: '白银', name: '白银', value: 'C', permission: 'silver' },
    { label: '黄金', name: '黄金', value: 'D', permission: 'gold' },
    { label: '钻石', name: '钻石', value: 'E', permission: 'diamond' },
    ],

    // 枚举键名与枚举值的映射
    
    /* 代理等级-黑铁 */
    blackIron: 'A',
    
    /* 代理等级-青铜 */
    bronze: 'B',
    
    /* 代理等级-白银 */
    silver: 'C',
    
    /* 代理等级-黄金 */
    gold: 'D',
    
    /* 代理等级-钻石 */
    diamond: 'E',
    
     // 根据枚举值获取名称
    getNameByValue: function(val) {
      switch (val) {
        case 'A':
          return '黑铁'
        case 'B':
          return '青铜'
        case 'C':
          return '白银'
        case 'D':
          return '黄金'
        case 'E':
          return '钻石'
        default:
          return val
      }
    },
    // 根据枚举名获取枚举值
    getValueByName: function(name) {
       switch (name) {
         case '黑铁':
           return 'A'
         case '青铜':
           return 'B'
         case '白银':
           return 'C'
         case '黄金':
           return 'D'
         case '钻石':
           return 'E'
         default:
           return name
       }
    },
    // 根据枚举键名获取枚举值
    getValueByKey: function(key) {
       switch (key) {
         case 'blackIron':
           return 'A'
         case 'bronze':
           return 'B'
         case 'silver':
           return 'C'
         case 'gold':
           return 'D'
         case 'diamond':
           return 'E'
         default:
           return key
       }
    }
  }
  /*---------------- 代理等级枚举 结束 -------------------*/
  
  /** ---------------- 代理等级背景颜色枚举 开始 -------------------**/
  export const agentBGType= {

    // 代理等级背景颜色-集合
    list: [
        { label: 'A', name: 'A', value: '#474A56, #7D8395, #FFFFFF', permission: 'blackIronBG' },
    { label: 'B', name: 'B', value: '#0D90A1, #A4EBE7, #FFFFFF', permission: 'bronzeBG' },
    { label: 'C', name: 'C', value: '#C7CDE2, #B3BEE0, #FFFFFF', permission: 'silverBG' },
    { label: 'D', name: 'D', value: '#FFBF3E, #F5E29C, #FFFFFF', permission: 'goldBG' },
    { label: 'E', name: 'E', value: '#1, #7D8395, #FFFFFF', permission: 'diamondBG' },
    ],

    // 枚举键名与枚举值的映射
    
    /* 代理等级背景颜色-A */
    blackIronBG: '#474A56, #7D8395, #FFFFFF',
    
    /* 代理等级背景颜色-B */
    bronzeBG: '#0D90A1, #A4EBE7, #FFFFFF',
    
    /* 代理等级背景颜色-C */
    silverBG: '#C7CDE2, #B3BEE0, #FFFFFF',
    
    /* 代理等级背景颜色-D */
    goldBG: '#FFBF3E, #F5E29C, #FFFFFF',
    
    /* 代理等级背景颜色-E */
    diamondBG: '#1, #7D8395, #FFFFFF',
    
     // 根据枚举值获取名称
    getNameByValue: function(val) {
      switch (val) {
        case '#474A56, #7D8395, #FFFFFF':
          return 'A'
        case '#0D90A1, #A4EBE7, #FFFFFF':
          return 'B'
        case '#C7CDE2, #B3BEE0, #FFFFFF':
          return 'C'
        case '#FFBF3E, #F5E29C, #FFFFFF':
          return 'D'
        case '#1, #7D8395, #FFFFFF':
          return 'E'
        default:
          return val
      }
    },
    // 根据枚举名获取枚举值
    getValueByName: function(name) {
       switch (name) {
         case 'A':
           return '#474A56, #7D8395, #FFFFFF'
         case 'B':
           return '#0D90A1, #A4EBE7, #FFFFFF'
         case 'C':
           return '#C7CDE2, #B3BEE0, #FFFFFF'
         case 'D':
           return '#FFBF3E, #F5E29C, #FFFFFF'
         case 'E':
           return '#1, #7D8395, #FFFFFF'
         default:
           return name
       }
    },
    // 根据枚举键名获取枚举值
    getValueByKey: function(key) {
       switch (key) {
         case 'blackIronBG':
           return '#474A56, #7D8395, #FFFFFF'
         case 'bronzeBG':
           return '#0D90A1, #A4EBE7, #FFFFFF'
         case 'silverBG':
           return '#C7CDE2, #B3BEE0, #FFFFFF'
         case 'goldBG':
           return '#FFBF3E, #F5E29C, #FFFFFF'
         case 'diamondBG':
           return '#1, #7D8395, #FFFFFF'
         default:
           return key
       }
    }
  }
  /*---------------- 代理等级背景颜色枚举 结束 -------------------*/
  
  /** ---------------- 设备筛选状态枚举 开始 -------------------**/
  export const agentBGURLType= {

    // 设备筛选状态-集合
    list: [
        { label: 'A', name: 'A', value: '#474A56, #7D8395, #FFFFFF', permission: 'blackIronBG' },
    { label: 'B', name: 'B', value: '#0D90A1, #A4EBE7, #FFFFFF', permission: 'bronzeBG' },
    { label: 'C', name: 'C', value: '#C7CDE2, #B3BEE0, #FFFFFF', permission: 'silverBG' },
    { label: 'D', name: 'D', value: '#FFBF3E, #F5E29C, #FFFFFF', permission: 'goldBG' },
    { label: 'E', name: 'E', value: '#1, #7D8395, #FFFFFF', permission: 'diamondBG' },
    ],

    // 枚举键名与枚举值的映射
    
    /* 设备筛选状态-A */
    blackIronBG: '#474A56, #7D8395, #FFFFFF',
    
    /* 设备筛选状态-B */
    bronzeBG: '#0D90A1, #A4EBE7, #FFFFFF',
    
    /* 设备筛选状态-C */
    silverBG: '#C7CDE2, #B3BEE0, #FFFFFF',
    
    /* 设备筛选状态-D */
    goldBG: '#FFBF3E, #F5E29C, #FFFFFF',
    
    /* 设备筛选状态-E */
    diamondBG: '#1, #7D8395, #FFFFFF',
    
     // 根据枚举值获取名称
    getNameByValue: function(val) {
      switch (val) {
        case '#474A56, #7D8395, #FFFFFF':
          return 'A'
        case '#0D90A1, #A4EBE7, #FFFFFF':
          return 'B'
        case '#C7CDE2, #B3BEE0, #FFFFFF':
          return 'C'
        case '#FFBF3E, #F5E29C, #FFFFFF':
          return 'D'
        case '#1, #7D8395, #FFFFFF':
          return 'E'
        default:
          return val
      }
    },
    // 根据枚举名获取枚举值
    getValueByName: function(name) {
       switch (name) {
         case 'A':
           return '#474A56, #7D8395, #FFFFFF'
         case 'B':
           return '#0D90A1, #A4EBE7, #FFFFFF'
         case 'C':
           return '#C7CDE2, #B3BEE0, #FFFFFF'
         case 'D':
           return '#FFBF3E, #F5E29C, #FFFFFF'
         case 'E':
           return '#1, #7D8395, #FFFFFF'
         default:
           return name
       }
    },
    // 根据枚举键名获取枚举值
    getValueByKey: function(key) {
       switch (key) {
         case 'blackIronBG':
           return '#474A56, #7D8395, #FFFFFF'
         case 'bronzeBG':
           return '#0D90A1, #A4EBE7, #FFFFFF'
         case 'silverBG':
           return '#C7CDE2, #B3BEE0, #FFFFFF'
         case 'goldBG':
           return '#FFBF3E, #F5E29C, #FFFFFF'
         case 'diamondBG':
           return '#1, #7D8395, #FFFFFF'
         default:
           return key
       }
    }
  }
  /*---------------- 设备筛选状态枚举 结束 -------------------*/
  
  /** ---------------- 代理等级提成比例枚举 开始 -------------------**/
  export const agentProportion= {

    // 代理等级提成比例-集合
    list: [
        { label: 'A', name: 'A', value: '2%', permission: 'blackIronBG' },
    { label: 'B', name: 'B', value: '5%', permission: 'bronzeBG' },
    { label: 'C', name: 'C', value: '10%', permission: 'silverBG' },
    { label: 'D', name: 'D', value: '15%', permission: 'goldBG' },
    { label: 'E', name: 'E', value: '20%', permission: 'diamondBG' },
    ],

    // 枚举键名与枚举值的映射
    
    /* 代理等级提成比例-A */
    blackIronBG: '2%',
    
    /* 代理等级提成比例-B */
    bronzeBG: '5%',
    
    /* 代理等级提成比例-C */
    silverBG: '10%',
    
    /* 代理等级提成比例-D */
    goldBG: '15%',
    
    /* 代理等级提成比例-E */
    diamondBG: '20%',
    
     // 根据枚举值获取名称
    getNameByValue: function(val) {
      switch (val) {
        case '2%':
          return 'A'
        case '5%':
          return 'B'
        case '10%':
          return 'C'
        case '15%':
          return 'D'
        case '20%':
          return 'E'
        default:
          return val
      }
    },
    // 根据枚举名获取枚举值
    getValueByName: function(name) {
       switch (name) {
         case 'A':
           return '2%'
         case 'B':
           return '5%'
         case 'C':
           return '10%'
         case 'D':
           return '15%'
         case 'E':
           return '20%'
         default:
           return name
       }
    },
    // 根据枚举键名获取枚举值
    getValueByKey: function(key) {
       switch (key) {
         case 'blackIronBG':
           return '2%'
         case 'bronzeBG':
           return '5%'
         case 'silverBG':
           return '10%'
         case 'goldBG':
           return '15%'
         case 'diamondBG':
           return '20%'
         default:
           return key
       }
    }
  }
  /*---------------- 代理等级提成比例枚举 结束 -------------------*/
  
  /** ---------------- 申请成为代理状态枚举 开始 -------------------**/
  export const applyReviewStatus= {

    // 申请成为代理状态-集合
    list: [
        { label: '未申请', name: '未申请', value: '0', permission: 'notApplied' },
    { label: '待审核', name: '待审核', value: '1', permission: 'underReview' },
    { label: '通过', name: '通过', value: '2', permission: 'pass' },
    { label: '不通过', name: '不通过', value: '3', permission: 'didNotPass' },
    ],

    // 枚举键名与枚举值的映射
    
    /* 申请成为代理状态-未申请 */
    notApplied: '0',
    
    /* 申请成为代理状态-待审核 */
    underReview: '1',
    
    /* 申请成为代理状态-通过 */
    pass: '2',
    
    /* 申请成为代理状态-不通过 */
    didNotPass: '3',
    
     // 根据枚举值获取名称
    getNameByValue: function(val) {
      switch (val) {
        case '0':
          return '未申请'
        case '1':
          return '待审核'
        case '2':
          return '通过'
        case '3':
          return '不通过'
        default:
          return val
      }
    },
    // 根据枚举名获取枚举值
    getValueByName: function(name) {
       switch (name) {
         case '未申请':
           return '0'
         case '待审核':
           return '1'
         case '通过':
           return '2'
         case '不通过':
           return '3'
         default:
           return name
       }
    },
    // 根据枚举键名获取枚举值
    getValueByKey: function(key) {
       switch (key) {
         case 'notApplied':
           return '0'
         case 'underReview':
           return '1'
         case 'pass':
           return '2'
         case 'didNotPass':
           return '3'
         default:
           return key
       }
    }
  }
  /*---------------- 申请成为代理状态枚举 结束 -------------------*/
  
  /** ---------------- 支付方式枚举 开始 -------------------**/
  export const buyType= {

    // 支付方式-集合
    list: [
        { label: '支付宝', name: '支付宝', value: '1', permission: 'alipay' },
    { label: '微信', name: '微信', value: '2', permission: 'wechatPay' },
    ],

    // 枚举键名与枚举值的映射
    
    /* 支付方式-支付宝 */
    alipay: '1',
    
    /* 支付方式-微信 */
    wechatPay: '2',
    
     // 根据枚举值获取名称
    getNameByValue: function(val) {
      switch (val) {
        case '1':
          return '支付宝'
        case '2':
          return '微信'
        default:
          return val
      }
    },
    // 根据枚举名获取枚举值
    getValueByName: function(name) {
       switch (name) {
         case '支付宝':
           return '1'
         case '微信':
           return '2'
         default:
           return name
       }
    },
    // 根据枚举键名获取枚举值
    getValueByKey: function(key) {
       switch (key) {
         case 'alipay':
           return '1'
         case 'wechatPay':
           return '2'
         default:
           return key
       }
    }
  }
  /*---------------- 支付方式枚举 结束 -------------------*/
  
export default {
  
   yesOrNo,
  
   loginType,
  
   agentType,
  
   agentBGType,
  
   agentBGURLType,
  
   agentProportion,
  
   applyReviewStatus,
  
   buyType,
  
}
